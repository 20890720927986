var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"faq-contact mt-5"},[_c('b-row',{staticClass:"mt-5 pt-75 text-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('You still have a question?')))]),_c('b-card-text',{staticClass:"mb-3 text-center d-block"},[_vm._v(" "+_vm._s(_vm.$t('If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!'))+" ")])],1)],1),_c('div',{staticClass:"form_wrapper py-4"},[_c('validation-observer',{ref:"createObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit( _vm.onSubmit)}}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Category')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('Category')))]),_c('b-input-group',{attrs:{"label":_vm.$t('Category')}},[_c('b-form-select',{attrs:{"options":_vm.options,"placeholder":_vm.$t('Category'),"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('Please select an option'))+" ")])]},proxy:true}],null,true),model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t('Title')))]),_c('b-input-group',{attrs:{"label":_vm.$t('Title')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Title'),"id":"title","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('Description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t('Description')))]),_c('b-input-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('Description'),"id":"description","rows":"4","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"justify-content-center mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('Send')))])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }