<template>
  <!-- contact us -->
  <section class="faq-contact mt-5">
    <b-row class="mt-5 pt-75 text-center">
      <b-col cols="12">
        <h2>  {{ $t('You still have a question?')}}</h2>
        <b-card-text class="mb-3 text-center d-block">
          {{ $t('If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!')}}
        </b-card-text>
      </b-col>
    </b-row>
    <div class="form_wrapper py-4">
      <validation-observer #default="{handleSubmit}" ref="createObserver">
        <b-form @submit.stop.prevent="handleSubmit( onSubmit)">
          <b-row class="justify-content-center">
            <b-col cols="12" md="4" class="mt-1">
              <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Category')">
                <label>{{$t('Category')}}</label>
                <b-input-group :label="$t('Category')">
                  <b-form-select v-model="formData.category" :options="options" :placeholder="$t('Category')"
                                 :state="errors.length > 0 ? false:null">

                    <template #first>
                      <b-form-select-option :value="null" disabled> {{$t('Please select an option')}}  </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="12" md="4" class="mt-1">
              <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Title')">
                <label> {{$t('Title')}}</label>
                <b-input-group :label="$t('Title')">
                  <b-form-input
                      :placeholder="$t('Title')"
                      id="title"
                      :state="errors.length > 0 ? false:null"
                      v-model="formData.title"/>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="12" md="4" class="mt-1">
              <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('Description')">
                <label> {{$t('Description')}}</label>
                <b-input-group label="Description">

                  <b-form-textarea
                      :placeholder="$t('Description')"
                      id="description"
                      rows="4"
                      :state="errors.length > 0 ? false:null"
                      v-model="formData.description"/>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-3">
            <b-col cols="12" class="d-flex justify-content-center">
              <b-button type="submit" variant="success"> {{$t('Send')}}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </section>
  <!--/ contact us -->
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BFormGroup,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider, localize, localeChanged} from 'vee-validate'
import {
  required
} from '@validations'

export default {
  name: "QuestionForm",
  components: {
    ValidationObserver,
    localize,
    localeChanged,
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BFormInvalidFeedback,
    ValidationProvider,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption

  },
  data() {
    return {
      options: [
        {value: 'Events', text: 'Events'},
        {value: 'Payments', text: 'Payments'},

      ],
      formData: {
        category: null,
        title: null,
        description: null
      }
    }
  }, methods: {

    onSubmit() {
      // let url = `/faq/request`
      // UNCOMENT after API done
      this.$http.post(url, this.formData)
          .then(() => {
            this.formData.category = null
            this.formData.description = null
            this.formData.title = null
            this.$bvToast.toast(this.$i18n.t('Question send'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((error) => {
            this.$bvToast.toast(this.$i18n.t('Server error'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },

  }
}
</script>

<style scoped>

</style>