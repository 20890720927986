<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
          no-body
          class="faq-search"

      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
              {{ $t("Let's answer some questions") }}
          </h2>
          <b-card-text class="mb-2">
              {{ $t('or choose a category to quickly find the help you need') }}
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge position-relative">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon"/>
              </b-input-group-prepend>
              <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  :placeholder="$t('Search FAQ...')"
              />

              <feather-icon icon="XIcon" class="reset_action" @click="clearSearch"/>
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
          vertical
          content-class="col-12 col-md-8 col-lg-9"
          pills
          nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
          nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
            v-for="(categoryObj, categoryName, index) in filterFaqs"
            :key="categoryName"
            :active="index === 0 "
        >

          <!-- title -->
          <template #title>

            <span class="font-weight-bold">{{ categoryObj.name }}  ({{ categoryObj.entries.length }})</span>
          </template>


          <faq-question-answer :options="categoryObj"/>
        </b-tab>

      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->
    <QuestionForm/>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'
import QuestionForm from "@/views/faq/QuestionForm";

export default {
  components: {
    QuestionForm,
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: [],
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    filterFaqs() {
      if (this.faqSearchQuery) {
        const newFilteredData = JSON.parse(JSON.stringify(this.faqData))
        const filteredData = newFilteredData.map((item) => {
          const newEntries = item.entries.filter((entre) => {
            return entre.name.toLowerCase().includes(this.faqSearchQuery.toLowerCase());
          });
          item.entries = newEntries
          return   item
        })

        return filteredData.filter((item) => {
          return item.entries.length >0
        })
      }
      return this.faqData
    }
  },
  methods: {
    clearSearch() {
      this.faqSearchQuery = ""
    },
    fetchData() {
      this.$http.get('/client/faq').then(res => {
        this.faqData = res.data.items
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';

[dir] .nav-pills .nav-link.active, [dir] .nav-pills .show > .nav-link {
  background-color: #AD8A60;
  border: none;
}

.form_wrapper{
  background: rgba(186, 191, 199, 0.12);
}
.reset_action {
  cursor: pointer;
  z-index: 99999999;
  right: 15px;
  position: absolute;
  top: 11px;
}


@media screen and (min-width: 768px){
  .faq-navigation {
    height: 355px;
  }
}

</style>
